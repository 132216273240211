import Reactotron, { trackGlobalErrors } from 'reactotron-react-js';
import { Tools } from './Utilities';

declare global {
    interface Console {
        tron: {
            log: (...args: any[]) => void;
            prodLog: (...args: any[]) => void;
            error: (message: any, stack: any) => void;
            display: (config?: any) => void;
        };
    }
}

if (Tools.isDev()) {
    Reactotron.configure({
        name: 'BONMEA Website',
    })
        .use(trackGlobalErrors(null))
        .connect();

    if (Reactotron.clear) Reactotron.clear();

    console.tron = {
        log: Reactotron.log ? Reactotron.log : () => {},
        prodLog: () => {},
        error: (_, error) => {
            if (Reactotron.error) Reactotron.error(error, null);
        },
        display: Reactotron.display,
    };
} else {
    console.tron = {
        log: () => {},
        prodLog: data => {},
        error: (message: any, stack: any) => {},
        display: () => {},
    };
}
