const isDev = () => {
    return process.env.isDevelopment === 'true';
};

const isClientSide = () => {
    const isBrowser = typeof window !== `undefined`;
    return isBrowser;
};

export { isDev, isClientSide };
