module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/opt/TeamCity/buildAgent/work/7a964b4828c83819/Client/src/intl","languages":["en","de"],"defaultLanguage":"en","redirect":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#74a7b0","theme_color":"#74a7b0","display":"minimal-ui","icon":"src/Resources/Images/bonmea-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"599bd3690934dba84e8af31f8cc9a380"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
